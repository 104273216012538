<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('seeds_report.office_wise_sales_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      >
                      <template v-slot:label>
                      {{$t('warehouse_config.fiscal_year')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalList"
                      id="fiscal_year_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Organization" vid="org_id">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    >
                    <template v-slot:label>
                      {{$t('org_pro.organization')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                 </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="Measurement" vid="measurement_unit_id">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="measurement_unit_id"
                    >
                    <template v-slot:label>
                      {{$t('seedsSeeds.unitName')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.measurement_unit_id"
                      :options="unitNameList"
                      id="measurement_unit_id"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="office Type" vid="zone_office_type_id">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="zone_office_type_id"
                    >
                    <template v-slot:label>
                      {{$t('seedsSeeds.office_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.zone_office_type_id"
                      :options="officeTypeList"
                      id="zone_office_type_id"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="Sales Office" vid="to_sales_office_id">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="to_sales_office_id"
                    >
                    <template v-slot:label>
                      {{$t('seeds_report.sales_office_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.to_sales_office_id"
                      :options="officeList"
                      id="to_sales_office_id"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_type_id"
                        >
                        <template v-slot:label>
                            {{ $t('seeds_report.seed_type') }}
                        </template>
                        <v-select name="crop_type_id"
                            v-model="search.crop_type_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= cropTypeList
                            :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_name_id"
                        >
                        <template v-slot:label>
                            {{ $t('seeds_report.seed') }}
                        </template>
                        <v-select name="crop_name_id"
                            v-model="search.crop_name_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= cropNameList
                            :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="variety_id"
                        >
                        <template v-slot:label>
                        {{ $t('seedsSeeds.varietyName') }}
                        </template>
                        <v-select name="crop_name_id"
                        v-model="search.variety_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= varietyList
                        :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
                 <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="allocation_type"
                        >
                        <template v-slot:label>
                        {{ $t('seedsConfig.customer_type') }}
                        </template>
                        <v-select name="allocation_type"
                        v-model="search.allocation_type"
                        label="text"
                        :reduce="item => item.value"
                        :options= customerTypeList
                        :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
                 <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.from_date')"
                    label-for="email"
                    >
                    <template v-slot:label>
                    {{$t('globalTrans.from_date')}}
                    </template>
                    <flat-pickr class="form-control"
                    v-model="search.from_date"
                    placeholder="Select Date"
                    ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-cols-lg="4"
                    label-for="to_date"
                    >
                    <template v-slot:label>
                    {{$t('globalTrans.to_date')}}
                    </template>
                    <flat-pickr class="form-control"
                    v-model="search.to_date"
                    placeholder="Select Date"
                    ></flat-pickr>
                    </b-form-group>
                </b-col> -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('seeds_report.office_wise_sales_report_list') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <b-col>
                                        <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id" :key="search.org_id">
                                          {{ $t('seeds_report.office_wise_sales_report') }}
                                        </list-report-head>
                                      </b-col>
                                    </b-col>
                                  </b-row>
                                  <b-col>
                                    <div class="text-center">
                                        <table style="width:100%;color:black;">
                                            <tr v-if="search.fiscal_year">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                            </tr>
                                            <tr v-if="search.measurement_unit">
                                              <td align="right" style="width:45%">{{ $t('seedsSeeds.unitName') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.measurement_unit_bn : search.measurement_unit }}</td>
                                            </tr>
                                            <tr v-if="search.office_type">
                                                <td align="right" style="width:45%">{{ $t('seedsSeeds.office_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.office_type_bn : search.office_type }}</td>
                                            </tr>
                                            <tr v-if="search.off_dealer_farmer_comp_id">
                                                <td align="right" style="width:45%">{{ $t('seeds_report.dealer_registration') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ $n(search.off_dealer_farmer_comp_id, { useGrouping:false }) }}</td>
                                            </tr>
                                            <tr v-if="search.crop_type_name">
                                                <td align="right" style="width:45%">{{ $t('seedsConfig.cropType') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_type_name_bn : search.crop_type_name }}</td>
                                            </tr>
                                            <tr v-if="search.crop_name">
                                                <td align="right" style="width:45%">{{ $t('seedsConfig.cropName') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_name_bn : search.crop_name }}</td>
                                            </tr>
                                            <tr v-if="search.variety_name">
                                                <td align="right" style="width:45%">{{ $t('seedsConfig.variety') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.variety_name_bn : search.variety_name }}</td>
                                            </tr>
                                            <tr v-if="search.allocation_type">
                                                <td align="right" style="width:45%">{{ $t('seedsConfig.customer_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.allocation_bn : search.allocation_en }}</td>
                                            </tr>
                                            <tr v-if="search.from_date">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.from_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{  search.from_date | dateFormat }}</td>
                                            </tr>
                                            <tr v-if="search.to_date">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.to_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{  search.to_date | dateFormat }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                  </b-col>
                                  <div class="text-black">
                                <hr>
                              </div>
                                <template v-if="datas.length">
                                  <b-row v-for="(item, parentIndex) in datas" :key="parentIndex">
                                    <b-col>
                                      <h4>{{ $t('seeds_report.sales_office_name') }} : {{ $i18n.locale === 'en' ? item.zone_office_name : item.zone_office_name_bn }}</h4>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('seeds_report.seed_type') }}</b-th>
                                            <b-th class="text-center">{{ $t('seeds_report.seed') }}</b-th>
                                            <b-th class="text-center">{{ $t('seedsSeeds.varietyName') }}</b-th>
                                            <b-th class="text-center">{{ $t('seeds_report.sales_qnty') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <b-tr v-for="(info, index) in item.sales_office_info" :key="index">
                                          <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.crop_type_name_bn : info.crop_type_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.crop_name_bn : info.crop_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.variety_name_bn : info.variety_name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.salesQuantity, {useGrouping: false})}}</b-td>
                                          </b-tr>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </template>
                                <template v-else>
                                   <div class="text-center">
                                      {{ $t('globalTrans.noDataFound') }}
                                    </div>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { officeWiseSalesReport, reportHeadingList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
      this.search.org_id = this.$store.state.dataFilters.orgId
      this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            org_id: 0,
            zone_office_type_id: 0,
            measurement_unit_id: 2,
            to_sales_office_id: 0,
            crop_type_id: '',
            crop_name_id: '',
            variety_id: '',
            allocation_type: '',
            date_from: 0,
            date_to: 0
          },
          seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
          reportHeadingList: reportHeadingList,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          officeList: [],
          cropNameList: [],
          varietyList: [],
          officeTypeList: [],
          customerTypeList: [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Institute' : 'ইনস্টিটিউট' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Farmer' : 'কৃষক' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Dealer' : 'ডিলার' }
            ]
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
    },
    computed: {
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        orgList: function () {
          const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return tmpData.map(item => {
              if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
              } else {
                return { value: item.value, text: item.text }
              }
            })
        },
        cropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        currentFiscalYearId: function () {
          return this.$store.state.SeedsFertilizer.currentFiscalYearId
        },
        unitNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.unitList
        }
    },
    watch: {
      'search.crop_type_id': function (newVal, oldVal) {
        this.search.crop_name_id = this.$t('globalTrans.select')
        this.cropNameList = []
        if (newVal) {
            this.cropNameList = this.getCropNameList(newVal)
        }
      },
      'search.crop_name_id': function (newVal, oldVal) {
        this.search.variety_id = this.$t('globalTrans.select')
        this.varietyList = []
          if (newVal) {
              this.varietyList = this.getVarietyList(newVal)
          } else {
              this.varietyList = []
          }
      },
      'search.org_id': function (newVal, oldVal) {
        this.search.zone_office_type_id = this.$t('globalTrans.select')
        this.officeTypeList = []
        if (newVal) {
          this.officeTypeList = this.getOfficeTypeList(newVal)
          this.search.zone_office_type_id = this.$store.state.dataFilters.officeTypeId
        }
      },
      'search.zone_office_type_id': function (newVal, oldVal) {
        this.search.to_sales_office_id = this.$t('globalTrans.select')
        this.officeList = []
        if (newVal) {
          this.officeList = this.getOfficeList(newVal)
          this.search.to_sales_office_id = this.$store.state.dataFilters.officeId
        }
      }
    },
    methods: {
        pdfExport () {
          const reportTitle = this.$t('seeds_report.office_wise_sales_report')
          ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.search.org_id, reportTitle, this.datas, this, this.search)
        },
        async searchData () {
        this.getCustomDataSearch()
        this.loading = true
        this.showData = true
        const params = Object.assign({}, this.search)
            await RestApi.getData(seedFertilizerServiceBaseUrl, officeWiseSalesReport, params).then(response => {
            if (response.success) {
                if (response.data) {
                 this.datas = this.relationData(response.data)
                }
            } else {
                this.datas = []
            }
            })
            this.loading = false
        },
        relationData (data) {
          const officeStore = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
          const cropTypeList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
          const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
          const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1)
          const listData = data.map(item => {
            const officeObj = officeStore.find(office => office.value === item.to_sales_office_id)

            const officeData = {
              zone_office_name: officeObj !== undefined ? officeObj.text_en : ' ',
              zone_office_name_bn: officeObj !== undefined ? officeObj.text_bn : ' '
            }
            item.sales_office_info = item.sales_office_info.map(officeData => {
              const cropTypeObj = cropTypeList.find(cropType => cropType.value === officeData.crop_type_id)
              const cropNameObj = cropNameList.find(cropName => cropName.value === officeData.crop_name_id)
              const varietyObj = varietyList.find(variety => variety.value === officeData.variety_id)
              const cropTypeData = {
                crop_type_name: cropTypeObj !== undefined ? cropTypeObj.text_en : ' ',
                crop_type_name_bn: cropTypeObj !== undefined ? cropTypeObj.text_bn : ' '
              }
              const cropNameData = {
                crop_name: cropNameObj !== undefined ? cropNameObj.text_en : ' ',
                crop_name_bn: cropNameObj !== undefined ? cropNameObj.text_bn : ' '
              }
              const varietyData = {
                variety_name: varietyObj !== undefined ? varietyObj.text_en : ' ',
                variety_name_bn: varietyObj !== undefined ? varietyObj.text_bn : ' '
              }
              return Object.assign({}, officeData, cropTypeData, cropNameData, varietyData)
            })

            return Object.assign({}, item, officeData)
          })

          return listData
        },
        getCustomDataSearch () {
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.search.fiscal_year = fiscalYearObj.text_en
            this.search.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.search.fiscal_year = ''
            this.search.fiscal_year_bn = ''
          }
          const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(officeType => officeType.value === parseInt(this.search.zone_office_type_id))
          if (typeof officeTypeObj !== 'undefined') {
            this.search.office_type = officeTypeObj.text_en
            this.search.office_type_bn = officeTypeObj.text_bn
          } else {
            this.search.office_type = ''
            this.search.office_type_bn = ''
          }
          const measurementUnitObj = this.$store.state.SeedsFertilizer.commonObj.unitList.find(unit => unit.value === this.search.measurement_unit_id)
          this.search.measurement_unit = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_en : ''
          this.search.measurement_unit_bn = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_bn : ''
          const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(item => item.value === this.search.crop_type_id)
            this.search.crop_type_name = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_en : ''
            this.search.crop_type_name_bn = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_bn : ''

          const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(item => item.value === this.search.crop_name_id)
            this.search.crop_name = typeof cropNameObj !== 'undefined' ? cropNameObj.text_en : ''
            this.search.crop_name_bn = typeof cropNameObj !== 'undefined' ? cropNameObj.text_bn : ''

          const varietyNameObj = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(item => item.value === this.search.variety_id)
            this.search.variety_name = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_en : ''
            this.search.variety_name_bn = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_bn : ''

          const allocationObj = this.customerTypeList.find(item => item.value === this.search.allocation_type)
            this.search.allocation_en = typeof allocationObj !== 'undefined' ? allocationObj.text : ''
            this.search.allocation_bn = typeof allocationObj !== 'undefined' ? allocationObj.text : ''
        },
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getOfficeList (officeTypeId) {
          const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
          if (officeTypeId) {
            return officeList.filter(item => item.office_type_id === officeTypeId)
          }
          return officeList
        },
        getCropNameList (croptypeID) {
          const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === croptypeID)
          return cropNameList
        },
        getVarietyList (cropNameId) {
          const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1 && item.crop_name_id === cropNameId)
          return varietyList
        }
    }
}
</script>
