import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = []
        if (search.fiscal_year) {
          const rowItem = [
            { text: vm.$t('warehouse_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.measurement_unit) {
          const rowItem = [
            { text: vm.$t('seedsSeeds.unitName'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.measurement_unit_bn : search.measurement_unit, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.office_type) {
          const rowItem = [
            { text: vm.$t('seedsSeeds.office_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.office_type_bn : search.office_type, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.crop_type_name) {
          const rowItem = [
            { text: vm.$t('seedsConfig.cropType'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.crop_type_name_bn : search.crop_type_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.crop_name) {
          const rowItem = [
            { text: vm.$t('seedsConfig.cropType'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.crop_name_bn : search.crop_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.variety_name) {
          const rowItem = [
            { text: vm.$t('seedsConfig.variety'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.variety_name_bn : search.variety_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.allocation_type) {
          const rowItem = [
            { text: vm.$t('seedsConfig.seedClass'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.allocation_bn : search.allocation_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        newDatas.forEach((info2, index) => {
          if (info2.to_sales_office_id) {
            const upname = (i18n.locale === 'bn') ? info2.zone_office_name_bn : info2.zone_office_name
            pdfContent.push({ text: vm.$t('seeds_report.sales_office_name') + ':' + upname, style: 'header2', alignment: 'left' })
          }
          const allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('seeds_report.seed_type'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('seeds_report.seed'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('seedsSeeds.varietyName'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('seeds_report.sales_qnty'), style: 'th', alignment: 'center', bold: true }
            ]
          ]
          info2.sales_office_info.forEach((info, index2) => {
            allRows.push([
              { text: vm.$n(index2 + 1), alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.crop_type_name_bn : info.crop_type_name, alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name, alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.variety_name_bn : info.variety_name, alignment: 'center', style: 'td' },
              { text: vm.$n(info.salesQuantity, { useGrouping: false }), alignment: 'center', style: 'td' }
            ])
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['20%', '20%', '20%', '20%', '20%'],
              body: allRows
            }
          })
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('office-wise-sales-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
